/* beneficios ficha */

.wrap-beneficio:nth-of-type(2n) .card-header {
  background: #dcf5e6d3;
}

.wrap-beneficio:nth-of-type(2n) .card-resumo-beneficio {
  background: #dcf5e6d3;
}
/* fim beneficios ficha */


/* listas ficha */

ul.list-ul {
  list-style-type: none;
  padding: 0;
  font-size: 1rem;
}

ul.list-ul li.list-item {
  padding-right: 1.1rem;
  padding-left: 1.1rem;
  border-bottom: 0.7px solid #dddcdc;
  display: flex;
  vertical-align: middle;
}

ul.list-ul li.list-item span {
  margin-top: auto;
  margin-bottom: auto;
}

ul.list-ul li.list-item:nth-of-type(odd) {
  background: #f7f7f7;
}

ul.list-ul li.list-item:last-child {
  border-bottom: none;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

ul.list-ul li.list-item.quebra-coluna:nth-child(odd) {
  clear: both;
  float: left;
  width: 50%;
}

ul.list-ul.list-beneficio:last-child(2) {
  border-bottom: none;
  border-bottom-left-radius: 0.5rem;
}

ul.list-ul.list-beneficio:last-child {
  border-bottom: none;
  border-bottom-right-radius: 0.5rem;
}

ul.list-ul li.list-item.quebra-coluna.striped-marcado {
  background: #f7f7f7;
}

ul.list-ul li.list-item.quebra-coluna.striped-nao-marcado {
  background: #ffffff;
}

ul.list-ul span.identificador {
  font-weight: bolder;
  text-transform: uppercase;
  width: auto;
}

ul.list-ul span.dado {
  margin-left: auto;
  text-align: right;
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

ul.list-ul li.list-item.list-item-cadastro {
  height: 3.15rem;
}

ul.list-ul li.list-item.list-item-beneficio{
  height: 2.02rem;
}

ul.list-ul li.list-item.list-item-telefone {
  height: 3.65rem;
}

ul.list-ul li.list-item.list-item-telefone span.identificador {
  text-align: left;
  width: 30%;
}

ul.list-ul li.list-item.list-item-telefone span.dado {
  margin-left: 0.2rem;
  text-align: left;
  width: 47%;
}

ul.list-ul li.list-item.list-item-telefone .btn-telefone {
  padding: 0rem;
  opacity: 0.6;
  cursor: pointer;
  line-height: 3.65rem;
}

ul.list-ul li.list-item.list-item-telefone .btn-telefone:hover {
  opacity: 1;
}

ul.list-ul li.list-item.list-item-telefone .btn-telefone i {
  font-size: 1.5rem;
}

ul.list-ul li.list-item.list-item-telefone .btn-telefone.btn-whats {
  color: #2b9c38;
  margin-left: 0.5rem;
}

ul.list-ul li.list-item.list-item-telefone .btn-telefone.btn-clicktocall {
  color: #390258;
  margin-left: 0.5rem;
}

ul.list-ul li.list-item.list-item-telefone .btn-telefone.btn-clicktocall i{
  font-size: 1.2rem;
}

ul.list-ul li.list-item.list-item-telefone .btn-telefone.btn-copy {
  color: #2b7c9c;
  margin-left: 0.5rem;
}


/* fim listas fichas */