@media print {



    /* Oculte o cabeçalho */
    @page {
        margin-top: 0px;
    }

    /* Oculte o rodapé */
    @page {
        margin-bottom: 0px;
    }

    .pagetitle {
        background-color: #000;
    }

    .header {
        background-color: #000;
    }


    /* Ocultar o cabeçalho e o rodapé durante a impressão */
    header,
    footer,
    aside {
        background-color: #000;
        display: none !important;
    }

    /* Estilos para a div de conteúdo que será impressa */
    .conteudo {
        /* Adicione estilos específicos de impressão para a div de conteúdo */
        font-size: 12pt;
        /* Tamanho da fonte */
        padding: 0px;
        /* Espaçamento interno */
        /* Adicione outros estilos conforme necessário */
    }

}